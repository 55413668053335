import { Package } from '@apx-ui/apx-web-api-v1';

import { IOrderDeliveryData, IProduct } from '../interfaces';
import { AbstractOrderModel } from './abstract-order.model';

export class DeliveryOrderModel extends AbstractOrderModel<IOrderDeliveryData> {

  constructor(
    protected override data: IOrderDeliveryData,
    protected override packages: Package[],
  ) {
    super(data, packages);
  }

  isOrderDateExpired(): boolean {
    const checkDate = new Date(this.data.CriticalDate);
    checkDate.setDate(checkDate.getDate() + 30);
    return Date.now() > checkDate.getTime();
  }

  isOffCycleOrderExpired(): boolean {
    return this.data.isNonRoutineOrder && this.isOrderDateExpired();
  }

  getTankName(): string {
    return this.data.TankName;
  }

  getCapturedTimeStamp(): Date {
    return new Date(this.data.CapturedTimeStamp);
  }

  getInjectionPoint(): string {
    return this.data.InjectionPoint;
  }

  getFrequency(): number {
    return this.data.Frequency;
  }

  override getSiteName(): string {
    return this.data.SiteName;
  }

  getDueDate(): Date {
    return this.data.DueDate;
  }

  isDeliveryCaptured(): boolean {
    return this.data.DeliveryCaptured;
  }

  getDilutionProduct(): IProduct[] {
    return this.data?.DilutionProduct;
  }

}
