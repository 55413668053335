export abstract class Filter {

  static create(filterData): Filter {

    switch (filterData.filterType) {
      case 'date':
        return new DateFilter(filterData.dateFrom, filterData.dateTo, filterData.type);
      case 'text':
        return new TextFilter(filterData.filter, filterData.filterType, filterData.type);
      default:
        throw new Error(`Unsupported filter type: ${filterData.filterType}`);
    }
  }

  static buildFilterObject(filtersObject: any): { [key: string]: string} {
    const result = {};

    for (const key in filtersObject) {
      const filterInstance = Filter.create(filtersObject[key]);
      result[key] = filterInstance.getValue();
    }

    return result;
}

  constructor() {
      if (new.target === Filter) {
        throw new TypeError('Cannot instantiate abstract class');
      }
  }

  abstract getValue(): string;
}

class DateFilter extends Filter {
  private dateFrom: string;
  private dateTo: string;
  private type: string;

  constructor(dateFrom, dateTo, type) {
      super();
      this.dateFrom = dateFrom;
      this.dateTo = dateTo;
      this.type = type;
  }

  getValue(): string {
      return new Date(this.dateFrom).toISOString();
  }
}

class TextFilter extends Filter {
  private filter: string;
  private filterType: string;
  private type: string;

  constructor(filter, filterType, type) {
    super();
    this.filter = filter;
    this.filterType = filterType;
    this.type = type;
  }

  getValue(): string {
    return this.filter;
  }

}
