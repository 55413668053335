import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'apx-ui-shared-sample-view-cell',
  templateUrl: './sample-view-cell.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SampleViewCellComponent implements ICellRendererAngularComp {
  cellValue: string;
  cellAction: any = null;

  private params: any;

  agInit(params: any): void {
    this.params = params;
    this.cellValue = params.value;
    this.cellAction = params.cellAction;
  }

  refresh(params: any): boolean {
    this.cellValue = params.value;
    this.cellAction = params.cellAction;
    return true;
  }

  openSampleDialog(): void {
    if (this.cellAction) {
      this.cellAction(this.params);
    }
  }
}
