import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import moment from 'moment-timezone';
import { distinctUntilChanged, noop, Subscription } from 'rxjs';

@Component({
  selector: 'apx-ui-shared-date-time',
  templateUrl: './date-time.component.html',
  styleUrls: ['./date-time.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateTimeComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DateTimeComponent),
      multi: true,
    },
  ],
})
export class DateTimeComponent implements ControlValueAccessor, Validator, OnInit, OnDestroy {
  @Input() datePlaceHolder;
  @Input() timePlaceHolder;
  @Input() maxDate: Date;

  @Output() valueChange = new EventEmitter<number>();

  form: UntypedFormGroup;
  private formChangesSubscription?: Subscription;
  private innerValue: any;
  private innerDisabled = false;
  private changeFn: (v: number) => void = noop;
  private touchedFn: () => void = noop;

  constructor(
    private readonly fb: UntypedFormBuilder,
  ) {
  }

  @Input() set disabled(v: boolean | string) {
    const newValue = v === true || v === 'true';
    if (this.innerDisabled === newValue) {
      return;
    }
    this.innerDisabled = newValue;
    if (this.form) {
      this.setDisabledState(this.innerDisabled);
    }
  }

  @Input()
  set value(v: any) {
    if (this.innerValue === v) {
      return;
    }
    this.innerValue = v;
    if (this.form) {
      const date = moment(this.innerValue);

      const dateTime = {
        date: date.toDate(),
        time: date.format('HH:mm'),
      };
      this.form.patchValue({ ...dateTime }, { emitEvent: false });
    }
  }

  get value(): any {
    return this.innerValue;
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      date: [new Date(), Validators.required],
      time: [moment().local(true).format('HH:mm'), Validators.required],
    });

    this.formChangesSubscription = this.form.valueChanges
      .pipe(
        distinctUntilChanged(),
      )
      .subscribe(formValue => {
        const [h, m] = formValue.time.split(':');

        const dataTime = new Date(formValue.date);
        dataTime.setHours(+h);
        dataTime.setMinutes(+m);

        this.innerValue = dataTime;

        this.touchedFn();
        this.changeFn(this.innerValue);
        this.valueChange.emit(this.innerValue);
      });
  }

  ngOnDestroy(): void {
    this.formChangesSubscription?.unsubscribe();
  }

  writeValue(value: number): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.changeFn = fn;
  }

  registerOnTouched(fn: any): void {
    this.touchedFn = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  validate(c: AbstractControl): ValidationErrors | null {
    return this.form.valid
      ? null
      : {
        sampleHeaderError: true,
      };
  }

}
