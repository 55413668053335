import { Package } from '@apx-ui/apx-web-api-v1';

import { BatchOrder, convertUoM, IFlush, IProduct } from '../interfaces';
import { AbstractOrderModel } from './abstract-order.model';

export class BatchOrderModel extends AbstractOrderModel<BatchOrder> {

  constructor(
    protected override data: BatchOrder,
    protected override packages: Package[],
  ) {
    super(data, packages);
  }

  getInjectionPoint(): string {
    return this.data.InjectionPoint;
  }

  getDueDate(): Date {
    return new Date(this.data.DueDate);
  }

  getDeliveryDate(): Date {
    return this.data.DeliveryDate;
  }

  getFlush(): IFlush {
    return this.data.Flush;
  }

  getFlushVolume(): number {
    return this.data.FlushVolume;
  }

  getFrequency(): number {
    return this.data.Frequency;
  }

  isOrderDateExpired(): boolean {
    const checkDate = new Date(this.data.DueDate);
    checkDate.setDate(checkDate.getDate() + 30);
    return Date.now() > checkDate.getTime();
  }

  isOffCycleOrderExpired(): boolean {
    return this.data.isNonRoutineOrder && this.isOrderDateExpired();
  }

  getDilutionProduct(): IProduct[] {
    return [];
  }

  getCapturedTimeStamp(): string {
    return this.data?.CapturedTimeStamp || '';
  }

  override calcTargetVolumeBase(product: IProduct): number {
    return +(this.data.TargetVolume * convertUoM(this.data.VolumeUoM, product.UofM)).toFixed(2);
  }

  getRouteLocationCustomerFieldTitleForBatchOrder(): string {
    const items = [
      this.data.CustomerName, this.data.Field, this.data.Route, this.data.Site, this.data.InjectionPoint,
    ].filter(i => !!i);

    return items.join(' | ');
  }

}
