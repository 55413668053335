import { Package } from '@apx-ui/apx-web-api-v1';

import { ContinuousOrder, ContinuousOrderType, convertUoM, IProduct } from '../interfaces';
import { AbstractOrderModel } from './abstract-order.model';

export class ContinuousOrderModel extends AbstractOrderModel<ContinuousOrder> {

  constructor(
    protected override data: ContinuousOrder,
    protected override packages: Package[],
  ) {
    super(data, packages);
  }

  static calcTargetVolumeBase(product: IProduct, data: ContinuousOrder): number {
    return Math.ceil(
      ((product.DilutionPercentage / 100) * (data.TargetVolume))
      * convertUoM(data.VolumeUoM, product.UofM),
    );
  }

  getContinuousOrderType(): ContinuousOrderType {
    return this.data.isNonRoutineOrder
      ? ContinuousOrderType.OFF_CYCLE
      : this.data.isScheduleContinuous
        ? ContinuousOrderType.SCHEDULED
        : ContinuousOrderType.BASE;
  }

  getDeliveryDate(): Date {
    return new Date(this.data.DeliveryDate);
  }

  getCriticalDate(): Date {
    return this.data.CriticalDate;
  }

  getTankName(): string {
    return this.data.TankName;
  }

  getTankCapacity(): number {
    return this.data.TankCapacity;
  }

  getTankMaxFill(): number {
    return this.data.TankMaxFill;
  }

  getFrequency(): number {
    return this.data.Frequency;
  }

  getInjectionPoint(): string {
    return this.data.InjectionPoint;
  }

  getDilutionProduct(): IProduct[] {
    return this.data.DilutionProduct;
  }

  getIsNonRoutineOrder(): boolean {
    return this.data.isNonRoutineOrder;
  }

  isOrderDateExpired(): boolean {
    const checkDate = new Date(this.data.CriticalDate);
    checkDate.setDate(checkDate.getDate() + 30);
    return Date.now() > checkDate.getTime();
  }

  isOffCycleOrderExpired(): boolean {
    return this.data.isNonRoutineOrder && this.isOrderDateExpired();
  }

  getEmptyDate(): Date {
    return this.data.EmptyDate;
  }

  getCapturedTimeStamp(): string {
    return this.data?.CapturedTimeStamp || '';
  }

}
