<div fxLayout="row"
     fxLayoutAlign="start center">

  <button mat-icon-button
          aria-label="openDialog"
          color="primary"
          class="mr-4 ml--12"
          matTooltip="Edit Field"
          (click)="openSampleDialog()">
    <mat-icon>edit</mat-icon>
  </button>

  {{ cellValue }}
</div>
